import { MouseEvent } from "react";

export type NavProps = {
  label: string;
  onScrollToTarget?: () => void; // Optional because the last target may not need it
};

export const handleNavigation = (
  e: MouseEvent<HTMLButtonElement>,
  onScrollToTarget: () => void
) => {
  e.stopPropagation();
  onScrollToTarget?.();
};
