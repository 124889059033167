import { useState } from "react";
import { Project } from "../../../models/project";

export const ProjectItem: React.FC<{ project: Project }> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150; // Set a threshold for description length

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const displayDescription = () => {
    if (props.project.description.length > maxLength && !isExpanded) {
      return `${props.project.description.slice(0, maxLength)}...`;
    }
    return props.project.description;
  };
  return (
    <div className="mt-6 bg-gray-100 max-w-sm rounded overflow-hidden shadow-lg md:mr-8">
      {props.project.images.length > 0 && (
        <div className="w-full h-full">
          <img
            className="w-full h-64"
            src={props.project.images[0]}
            alt="Project"
          />
        </div>
      )}
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-gray-900">
          {props.project.title}
        </div>
        <p className="text-gray-700 text-base">{displayDescription()}</p>
        {props.project.description.length > maxLength && (
          <button onClick={handleToggle} className="text-sky-600 underline">
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
      <div className="bg-gray-300 w-full pt-6">
        <div className="flex flex-wrap justify-around gap-8 ml-8 mr-8 pb-6">
          {props.project.tags[0].split(",").map((tag, idx) => {
            const cleanTag = tag.replace(/\s+/g, "");
            const widthClass = cleanTag === "Java" ? "w-8" : "w-12";

            return (
              <div
                key={idx}
                className={`inline-block relative h-12 ${widthClass}`}
              >
                <img
                  src={`/technologies/${cleanTag}.svg`}
                  alt={cleanTag}
                  title={cleanTag}
                  className="absolute inset-0 h-full w-full hover:scale-105 transition ease-in-out duration-300"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
