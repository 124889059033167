import { ForwardedRef, forwardRef } from "react";
import { NavProps } from "../../models/navRef";
import { NavLink } from "react-router-dom";

export const Contact = forwardRef<HTMLDivElement, NavProps>(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <div className="text-white text-2xl font-bold">Let's Connect!</div>
        <div className="text-gray-400 text-xl mt-4 leading-relaxed text-justify">
          Thank you for visiting my portfolio! If you're interested in
          collaborating on a project, have questions about my work, or just want
          to say hi, I'd love to hear from you. Feel free to contact me via
          <a href="https://www.linkedin.com/in/steven-geiger-3a2a77214/">
            &nbsp;
            <span className="font-bold underline">LinkedIn</span>
          </a>
          ,
          <a href="mailto:stevengforbusiness@gmail.com">
            &nbsp;
            <span className="font-bold underline">Email</span>
          </a>
          &nbsp; or use the{" "}
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              [
                "text-sm text-gray-400 text-xl mt-16 mb-8 underline font-bold",
                isActive ? "text-white" : null,
              ]
                .filter(Boolean)
                .join(" ")
            }
          >
            Contact form
          </NavLink>
          .
        </div>
      </div>
    );
  }
);
