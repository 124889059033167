import React, { ForwardedRef, forwardRef } from "react";
import { handleNavigation, NavProps } from "../../models/navRef";

// export function About() {
export const About = forwardRef<HTMLDivElement, NavProps>(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <React.Fragment>
        <div ref={ref}>
          <div className="text-white text-2xl font-bold">
            Hey there, I'm Steven.
          </div>
          <div className="text-gray-400 text-xl mt-4 leading-relaxed text-justify">
            I'm a 25-year-old software engineer with a Bachelor of Science in
            Computer Science. My journey into the world of programming began at
            a young age, thanks to a friend who introduced me to the craft. Ever
            since, coding has been a big passion of mine, driving me to
            constantly learn, innovate, and solve complex problems. In my free
            time, you'll find me breaking a sweat at the gym or indulging in
            some good food — often in the company of close friends. I also love
            tinkering with several side projects that allow me to explore new
            technologies and sharpen my skills further.
            {/* I'm a 25-year-old software engineer. I found my passion
          for programming when I was about 11 years old and a friend. What kept
          me on this journey is that I always find learning new things exciting
          and facing unfamiliar challenges. Currently, I work as a frontend
          developer at COMPANY. In addition to coding, I enjoy building side
          projects, writing blog posts, and reading books. When I'm not doing
          anything coding-related, I enjoy spending time with my family and
          working out. */}
          </div>
        </div>
        <button
          className="mt-16 pb-2 cursor-pointer focus:outline-none"
          style={{ border: "none", background: "transparent" }}
          onClick={(e) => handleNavigation(e, props.onScrollToTarget!)}
        >
          <p className="text-gray-500 hover:text-white">
            Projects&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 2 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="w-4 h-4 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
              />
            </svg>
          </p>
        </button>
      </React.Fragment>
    );
  }
);
