import React from "react";
import "./App.css";
import { Root } from "./routes/root/root";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Imprint } from "./components/Imprint/Imprint";
import { Credits } from "./components/Credits/Credits";
import { ContactForm } from "./components/Contact-Form/Contact-Form";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
    },
    {
      path: "/imprint",
      element: <Imprint />,
    },
    {
      path: "/credits",
      element: <Credits />,
    },
    {
      path: "/credits",
      element: <Credits />,
    },
    {
      path: "/contact",
      element: <ContactForm />,
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
