import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export function Credits() {
  return (
    <React.Fragment>
      <Header />
      <div
        className={`text-white text-4xl md:text-6xl mt-8 pl-4 pr-4 font-bold`}
      >
        Credits
      </div>
      <div className="pl-4 pr-4 text-gray-400 text-xl mt-4 leading-relaxed text-justify">
        Many parts of the Frontend were inspired by{" "}
        <a className="font-bold underline" href="https://www.miraya.bio/">
          Miraya Tech
        </a>
        . Thanks a lot for letting me use parts of your awesome design. ❤️
      </div>
      <span className="absolute bottom-0 pl-4">
        <Footer></Footer>
      </span>
    </React.Fragment>
  );
}
