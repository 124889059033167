import React, { useEffect } from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export function ContactForm() {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Add the Tally script dynamically
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;

    // Append the script to the head
    document.head.appendChild(script);

    // Load the embeds after the script has been added
    script.onload = () => {
      if ((window as any)["Tally"]) {
        (window as any)["Tally"].loadEmbeds();
      }
    };

    // Remove the script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="text-white text-4xl md:text-6xl mt-8 pl-4 pr-4 font-bold">
        Contact
      </div>
      <div className="px-4 pt-4">
        <iframe
          data-tally-src="https://tally.so/embed/3le5M5?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="292"
          title="Contact form"
        ></iframe>
        <div className="text-white text-4xl md:text-6xl mt-8 pl-4 pr-4 font-bold">
          Terms of Use
        </div>
        <div className="text-gray-400 pl-4 pr-4 text-xl mt-4 leading-relaxed text-justify">
          When using the contact form, the data you submit is collected by the
          external provider tally.so and is subject to its privacy policy. For
          more information and options for objection, please refer to the
          privacy policy of tally.so:{" "}
          <a href="https://tally.so/help/terms-and-privacy">
            https://tally.so/help/terms-and-privacy
          </a>
          . If you wish to contact me without using this third-party service,
          you can send me an email at stevengforbusiness@gmail.com.
        </div>
      </div>

      <div className="bottom-0 ml-4">
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
