import { Link, NavLink } from "react-router-dom";

export function Footer() {
  return (
    <div className="flex gap-4">
      <NavLink
        to="/imprint"
        className={({ isActive }) =>
          ["text-sm text-gray-600 mt-16 mb-8", isActive ? "text-white" : null]
            .filter(Boolean)
            .join(" ")
        }
      >
        Imprint & Privacy Statement
      </NavLink>
      <NavLink
        to="/credits"
        className={({ isActive }) =>
          ["text-sm text-gray-600 mt-16 mb-8", isActive ? "text-white" : null]
            .filter(Boolean)
            .join(" ")
        }
      >
        Credits
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) =>
          ["text-sm text-gray-600 mt-16 mb-8", isActive ? "text-white" : null]
            .filter(Boolean)
            .join(" ")
        }
      >
        Contact
      </NavLink>
    </div>
  );
}
