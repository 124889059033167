export function DSGVO() {
  return (
    <div className="pl-4 text-gray-400 pr-4">
      <div className="text-white text-2xl md:text-6xl mt-8 pr-4 font-bold">
        Privacy Policy
      </div>
      {/* <p>
        Max Mustermann
        <br />
        Mustermannstr.44
      </p> */}
      <p className="mt-4">
        Your privacy is very important to us. We process your data exclusively
        based on legal regulations (GDPR, TKG 2003). In this privacy policy, we
        inform you about the most important aspects of data processing within
        our website.
      </p>

      {/* <div className="text-white text-lg">Responsible Party</div>
      <p>
        The responsible party for data processing is Max Mustermann, located at
        Mustermannstr.44. You can reach us by phone at 123456789, by email at
        max@mustermann.de, or by mail at Mustermannstr.44.
      </p> */}

      <div className="text-white text-lg">Data Security</div>
      <p>
        We take appropriate precautions to protect your personal data in
        accordance with Art. 32 GDPR. This includes protection against
        unauthorized, unlawful, or accidental access, processing, loss, use, and
        manipulation.
      </p>

      <div className="text-white text-lg">Website</div>
      <p>
        Personal data refers to information that can be clearly attributed to an
        individual. This includes details such as full name, address, email, and
        phone number. During a visit to our website, additional data is
        automatically recorded for technical reasons (IP address, start and end
        of the session, date and time of the request, accessed subpage on our
        website, type and version of the browser, operating system, referrer
        URL).
      </p>

      <div className="text-white text-lg">Contacting Us</div>
      <p>
        If you contact us via the inquiry form on the website or by email, the
        data you provide (first name, last name, address, phone number, email)
        will be stored for six months for the purpose of processing the inquiry
        and in case of follow-up questions. We will not share this data without
        your consent.
      </p>

      <div className="text-white text-lg">Contract Execution</div>
      <p>
        We may process your data (first name, last name, address, phone number,
        email) to fulfill contracts or pre-contractual measures, and
        furthermore, if necessary, to protect legitimate interests of us or
        third parties.
      </p>

      {/* <div className="text-white text-lg">Cookies</div>
      <p>
        Our website uses so-called cookies. We use cookies to make our offer
        user-friendly. Cookies are small text files that are stored on your
        device via the browser.
      </p> */}

      <div className="text-white text-lg">Use of Google Fonts</div>
      <p>
        The provider is Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA. Their privacy policy can be found here:
        www.google.com/policies/privacy/. An opt-out is available here:
        https://adssettings.google.com/authenticated
      </p>

      <div className="text-white text-lg">Your Rights</div>
      <p>
        You have the basic rights to information, correction, deletion,
        restriction, data portability, revocation, and objection.
      </p>
      <p>
        If you believe that the processing of your data violates data protection
        law or that your data protection rights have otherwise been violated,
        you can file a complaint with us at stevengforbusiness@gmail.com or with
        the data protection authority.
      </p>
    </div>
    // <div className="pl-4 text-gray-400 pr-4">
    //   <div className="text-white text-2xl md:text-6xl mt-8 pr-4 font-bold">
    //     Datenschutzerklärung
    //   </div>
    //   <p>
    //     Max Mustermann
    //     <br />
    //     Mustermannstr.44
    //   </p>
    //   <p>
    //     Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
    //     verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
    //     gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
    //     Datenschutzinformationen informieren wir Sie über die wichtigsten
    //     Aspekte der Datenverarbeitung im Rahmen unserer Website.
    //   </p>

    //   <div className="text-white text-lg">Verantwortlicher</div>
    //   <p>
    //     Verantwortlicher für die Datenverarbeitung ist die Person Max Mustermann
    //     mit Sitz in Mustermannstr.44. Sie erreichen uns telefonisch unter
    //     123456789, per Mail unter max@mustermann.de oder postalisch unter der
    //     Anschrift Mustermannstr.44.
    //   </p>

    //   <div className="text-white text-lg">Datensicherheit</div>
    //   <p>
    //     Wir treffen nach Maß des Art 32 DSGVO entsprechende Vorkehrungen zum
    //     Schutz Ihrer personenbezogenen Daten. Diese betreffen insbesondere den
    //     Schutz vor unerlaubtem, rechtswidrigem oder auch zufälligem Zugriff,
    //     Verarbeitung, Verlust, Verwendung und Manipulation.
    //   </p>

    //   <div className="text-white text-lg">Webseite</div>
    //   <p>
    //     Personenbezogene Daten sind Angaben, die eindeutig einer Person
    //     zugeordnet werden können. Dazu gehören unter anderem Angaben wie
    //     vollständiger Name, Anschrift, E-Mail und Telefonnummer. Bei einem
    //     Besuch unserer Website werden aus technischen Gründen automatisch
    //     weitere Daten erfasst (IP-Adresse, Beginn und Ende der Sitzung, Datum
    //     und Uhrzeit der Anfrage, angesteuerte Unterseite auf unserer Webseite,
    //     Art und Version des Browsers, Betriebssystem, Referrer URL).
    //   </p>

    //   <div className="text-white text-lg">Kontakt mit uns</div>
    //   <p>
    //     Wenn Sie per Anfrageformular auf der Website oder per E-Mail Kontakt mit
    //     uns aufnehmen, werden Ihre angegebenen Daten (Vorname, Nachname,
    //     Adresse, Telefonnummer, E-Mail) zwecks Bearbeitung der Anfrage und für
    //     den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese
    //     Daten geben wir nicht ohne Ihre Einwilligung weiter.
    //   </p>

    //   <div className="text-white text-lg">Vertragsabwicklung</div>
    //   <p>
    //     Zur Erfüllung von Verträgen bzw. vorvertragliche Maßnahmen und darüber
    //     hinaus verarbeiten wir Ihre Daten (Vorname, Nachname, Adresse,
    //     Telefonnummer, E-Mail) gegebenenfalls, wenn es erforderlich ist, um
    //     berechtigte Interessen von uns oder Dritten zu wahren.
    //   </p>

    //   <div className="text-white text-lg">Cookies</div>
    //   <p>
    //     Unsere Website verwendet so genannte Cookies. Wir nutzen Cookies dazu,
    //     unser Angebot nutzerfreundlich zu gestalten. Cookies sind kleine
    //     Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt
    //     werden.
    //   </p>

    //   <div className="text-white text-lg">Verwendung von Google Fonts</div>
    //   <p>
    //     Anbieter ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View,
    //     CA 94043, USA. Deren Datenschutzrichtlinien finden Sie hier:
    //     www.google.com/policies/privacy/. Ein Opt-out wäre hier möglich:
    //     https://adssettings.google.com/authenticated
    //   </p>

    //   <div className="text-white text-lg">Ihre Rechte</div>
    //   <p>
    //     Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
    //     Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch
    //     zu.
    //   </p>
    //   <p>
    //     Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
    //     Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
    //     sonst in einer Weise verletzt worden sind, können Sie sich bei uns
    //     max@mustermann.de oder der Datenschutzbehörde beschweren.
    //   </p>
    // </div>
  );
}
