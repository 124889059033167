import { ForwardedRef, forwardRef } from "react";
import styles from "./Landing.module.css";
import { NavProps, handleNavigation } from "../../models/navRef";

export const Landing = forwardRef<HTMLDivElement, NavProps>(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={`relative h-screen`} ref={ref}>
        <div className={`flex items-center h-full`}>
          <div className={`flex w-full pl-12 md:pl-24 md:pb-8`}>
            <div className={`border-l-4 border-indigo-500 w-8`}></div>

            <div
              className={`flex flex-col space-y-4 lg:space-y-2 xl:space-y-4`}
            >
              <div className={`flex w-128 gap-4`}>
                <div
                  className={`w-32 h-3 md:w-64 md:h-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90 ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-4 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4 h-8`}>
                <div
                  className={`w-48 h-3 md:w-96 md:h-5 bg-gradient-to-r from-violet-200 to-pink-400 ${styles.slideInRightFirst}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4`}>
                <div
                  className={`w-24 h-3 md:w-48 md:h-5 bg-gradient-to-r from-violet-200 to-pink-400 ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-4 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightThird}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4`}>
                <div
                  className={`w-32 h-3 md:w-64 md:h-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-4 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightThird}`}
                ></div>
              </div>

              <div className={`ml-8`}>
                <div className={`flex w-128 gap-4`}>
                  <div
                    className={`w-16 md:w-32 h-3 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightFirst}`}
                  ></div>
                  <div
                    className={`w-4 h-3 md:w-8 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                  ></div>
                  <div
                    className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                  ></div>
                  <div
                    className={`w-16 h-3 md:w-32 md:h-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ${styles.slideInRightThird}`}
                  ></div>
                </div>

                <div
                  className={`text-white text-4xl md:text-6xl mt-8 ${styles.slideInRightFirst} font-bold`}
                >
                  Steven Geiger
                </div>
                <div
                  className={`text-white text-l md:text-2xl mt-2 mb-8 ${styles.slideInRightFirst}`}
                >
                  Software Engineer
                </div>
              </div>
              <div className={`flex w-128 gap-4`}>
                <div
                  className={`w-32 h-3 md:w-64 md:h-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90 ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-20 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4 h-16`}>
                <div
                  className={`w-48 h-3 md:w-96 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightFirst}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4 mt-48`}>
                <div
                  className={`w-24 h-3 md:w-32 md:h-5 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-4 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightThird}`}
                ></div>
              </div>
              <div className={`flex w-128 gap-4`}>
                <div
                  className={`w-32 h-3 md:w-64 md:h-5 bg-gradient-to-r from-red-300 to-orange-300 ${styles.slideInRightFirst}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-16 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-2 h-3 md:w-8 md:h-5 bg-gray-600 ${styles.slideInRightSecond}`}
                ></div>
                <div
                  className={`w-8 h-3 md:w-32 md:h-5 bg-gradient-to-r from-violet-200 to-pink-400 ${styles.slideInRightThird}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <button
          className={`absolute bottom-0 left-0 mb-4 ml-4 lg:ml-20 cursor-pointer focus:outline-none`}
          style={{ border: "none", background: "transparent" }}
          onClick={(e) => handleNavigation(e, props.onScrollToTarget!)}
        >
          <p className={`text-gray-500 hover:text-white`}>
            About&nbsp;
            <svg
              xmlns={`http://www.w3.org/2000/svg`}
              fill={`none`}
              viewBox={`0 2 24 24`}
              strokeWidth={`2.5`}
              stroke={`currentColor`}
              className={`w-4 h-4 inline-block`}
            >
              <path
                strokeLinecap={`round`}
                strokeLinejoin={`round`}
                d={`M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3`}
              />
            </svg>
          </p>
        </button>
      </div>
    );
  }
);
