import React, { useEffect } from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { DSGVO } from "./DSGVO/DSGVO";
import { NavLink } from "react-router-dom";

export function Imprint() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />

      <div className="text-white text-4xl md:text-6xl mt-8 pl-4 pr-4 font-bold">
        Imprint
      </div>
      <div className="pl-4 pr-4 text-gray-400 text-xl mt-4 leading-relaxed text-justify">
        <p>
          <span className="font-bold">Angaben gemäß &sect; 5 TMG</span>
          <br />
          Steven Geiger
          <br />
          c/o Postflex #4071
          <br />
          Emsdettener Str. 10
          <br />
          48268 Greven
          <br />
        </p>
        <p>
          <span className="font-bold">Kontakt:</span>
          <br />
          E-Mail: stevengforbusiness@gmail.com
          <br />
          Kontaktformular:{" "}
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              [
                "text-sm text-gray-400 text-xl mt-16 mb-8",
                isActive ? "text-white" : null,
              ]
                .filter(Boolean)
                .join(" ")
            }
          >
            steveng.dev/contact
          </NavLink>
          <br />
        </p>
      </div>

      <DSGVO />

      <div className="bottom-0 ml-4">
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
