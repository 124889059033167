import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Project } from "../../models/project";
import axios from "axios";
import { ProjectItem } from "./Project/ProjectItem";
import { NavProps, handleNavigation } from "../../models/navRef";

export const Projects = forwardRef<HTMLDivElement, NavProps>(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [projects, setProjects] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
      console.log(backendURL);
      // Fetch the projects from the backend
      axios
        .get(`${backendURL}/projects/all`)
        .then((response) => {
          setProjects(
            response.data
              .filter((project: Project) => project.title !== "Oddlite")
              .map((project: Project) => {
                project.images = project.images.map(
                  (img) => backendURL + "/" + img
                );
                return project;
              })
          );
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }, []); // The empty dependency array ensures this useEffect runs only once, similar to componentDidMount.

    return (
      <div>
        {/* Conditionally render loading, error or projects */}
        {loading && <div className="pb-16">{/* loading indicator */}</div>}

        {error && (
          <div className="text-red-700 text-2xl pb-16">
            Error fetching projects
          </div>
        )}

        {!loading && !error && (
          <div>
            <div className="text-white text-2xl font-bold" ref={ref}>
              Short Overview.
            </div>
            <div className="flex flex-wrap justify-center items-start">
              {projects.map((project, idx) => (
                <ProjectItem project={project} key={idx}></ProjectItem>
              ))}
            </div>
          </div>
        )}

        {/* This button will be displayed regardless of loading or error state */}
        <button
          className="mt-16 pb-2 cursor-pointer focus:outline-none"
          style={{ border: "none", background: "transparent" }}
          onClick={(e) => handleNavigation(e, props.onScrollToTarget!)}
        >
          <p className="text-gray-500 hover:text-white">
            Contact&nbsp;
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 2 24 24"
              strokeWidth="2.5"
              stroke="currentColor"
              className="w-4 h-4 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
              />
            </svg>
          </p>
        </button>
      </div>
    );
  }
);
