import { Link } from "react-router-dom";

export function Header() {
  return (
    <Link to={`/`}>
      <div className="pl-4 mt-4 text-gray-500 flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-4 0 30 30"
          strokeWidth={3}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        Home
      </div>
    </Link>
  );
}
