import React, { RefObject, useRef } from "react";
import { Landing } from "../../components/Landing/Landing";
import { About } from "../../components/About/About";
import { Projects } from "../../components/Projects/Projects";
import { Contact } from "../../components/Contact/Contact";
import { Footer } from "../../components/Footer/Footer";

export function Root() {
  // Manage multiple refs in an object
  const targetRefs: { [key: string]: RefObject<HTMLDivElement> } = {
    landing: useRef(null),
    about: useRef(null),
    projects: useRef(null),
    contact: useRef(null),
  };

  const handleScrollToTarget = (targetId: string) => {
    const current = targetRefs[targetId]?.current;
    current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <Landing
        label="landing"
        ref={targetRefs["landing"]}
        onScrollToTarget={() => handleScrollToTarget("about")}
      ></Landing>
      <div className="relative pl-4 pr-4 lg:pl-20 lg:pr-20">
        <About
          label="about"
          ref={targetRefs["about"]}
          onScrollToTarget={() => handleScrollToTarget("projects")}
        ></About>
        <Projects
          label="projects"
          ref={targetRefs["projects"]}
          onScrollToTarget={() => handleScrollToTarget("contact")}
        ></Projects>
        <Contact label="contact" ref={targetRefs["contact"]}></Contact>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
